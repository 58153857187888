import React from 'react';

const Signin1 = React.lazy(() => import('./Demo/Authentication/SignIn/SignIn1'));
const forgotPassword = React.lazy(() => import('./Demo/Authentication/forgotPassword/forgotPassword'));
const resetPassword = React.lazy(() => import('./Demo/Authentication/resetPassword/resetPassword'));
const PrivacyPolicy= React.lazy(()=>import('./Demo/PrivacyPolicy'));
const TermsAndConditions= React.lazy(()=>import('./Demo/TermsAndConditions'));

const route = [
    { path: '/Login', exact: true, name: 'Login', component: Signin1 },
    { path: '/forgot-password', exact: true, name: 'Forgot Password', component: forgotPassword },
    { path: '/reset-password/:token', exact: true, name: 'Reset Password', component: resetPassword },
   
    {path:'/privacy-policy',exact:true,name:'Privacy Policy',component:PrivacyPolicy},
    {path:'/terms-and-condition',exact:true,name:'Terms And Condition',component:TermsAndConditions},
   
    
];

export default route;